
import { defineComponent, inject, ref } from 'vue'
import { required as requiredRule } from '@/common/formValidationRules'
import QForm from 'quasar/src/components/form/QForm.js';
import { useProfile } from '@/composable/useProfile'
import { UseModal } from '@/composable/useModal'

export default defineComponent({
  name: 'AppResetPasswordModal',

  setup() {
    const success = ref(false)
    const showError = ref(false)
    const { resetPassword, pendingResetPassword } = useProfile()
    const formRef = ref<QForm | null>(null)
    const resetPasswordModal = inject<UseModal<unknown>>('reset-password-modal')
    const model = ref({
      email: '',
    })

    async function onResetPassword() {
      showError.value = false
      const isValid = await formRef.value?.validate()
      if (!isValid) return
      const data = await resetPassword(model.value.email)

      if (data === 'auth/user-not-found') {
        showError.value = true
      } else if (data === 'ok') {
        success.value = true
      } else {
        resetPasswordModal?.closeModal()
      }
    }
    return {
      resetPasswordModal,
      model,
      requiredRule,
      onResetPassword,
      formRef,
      pendingResetPassword,
      showError,
      success,
    }
  },
})
