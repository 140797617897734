<template>
  <div class="text-white text-caption" @click="() => signInSSO('saml.reges')">
    Powered by Reges
  </div>
</template>

<script lang="ts">
import { signInSSO } from '@/composable/useProfile'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SignInRegesSSO',

  setup() {
    return {
      signInSSO,
    }
  },
})
</script>
