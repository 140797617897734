
import AppLogo from '@/components/AppLogo.vue'
import QForm from 'quasar/src/components/form/QForm.js';
import { defineComponent, ref, reactive, provide } from 'vue'
import { required as requiredRule } from '@/common/formValidationRules'
import { useProfile, signInSSO } from '@/composable/useProfile'
import { routerPush } from '@/router'
import SignInRegesSSO from '@/components/signIn/SignInRegesSSO.vue'
import { useModal } from '@/composable/useModal'
import AppResetPasswordModal from '@/components/AppResetPasswordModal.vue'

export default defineComponent({
  name: 'SignIn',

  components: {
    AppLogo,
    SignInRegesSSO,
    AppResetPasswordModal,
  },

  setup() {
    const resetPasswordModal = useModal()
    provide('reset-password-modal', resetPasswordModal)
    const signInForm = ref<QForm | null>(null)
    const isSignInError = ref(false)
    const model = reactive({
      email: '',
      password: '',
    })
    const showPassword = ref(false)
    const { signIn, signInPending } = useProfile()

    async function onSignIn() {
      isSignInError.value = false
      const isValid = await signInForm.value?.validate()
      if (!isValid) return
      try {
        await signIn(model)
        routerPush('projects')
      } catch (error) {
        isSignInError.value = true
      }
    }

    function openResetPasswordModal() {
      resetPasswordModal.openModal()
    }

    return {
      showPassword,
      signInForm,
      signInPending,
      requiredRule,
      model,
      onSignIn,
      isSignInError,
      signInSSO,
      openResetPasswordModal,
      resetPasswordModal,
    }
  },
})
