<template>
  <q-layout>
    <q-page-container>
      <AppResetPasswordModal v-if="resetPasswordModal.state.show" />
      <q-page class="flex justify-center items-center q-pa-sm bg-dark">
        <div class="full-width flex justify-center items-center">
          <q-card class="full-width shadow-20" style="max-width: 600px" square>
            <q-card-section horizontal>
              <q-card-section
                class="gt-xs"
                :class="$style.gradient"
                style="width: 450px"
              >
                <div class="column full-height">
                  <div class="flex items-center">
                    <AppLogo color="white" />
                    <q-separator vertical color="white" class="q-ml-md" />
                    <h5 class="q-ml-md q-ma-none text-weight-medium text-white">
                      Avik
                    </h5>
                  </div>

                  <q-space />

                  <SignInRegesSSO />
                </div>
              </q-card-section>
              <q-card-section class="full-width">
                <div class="text-h6 q-mb-lg gt-xs">Logga in</div>
                <div class="flex items-center q-pb-md lt-sm">
                  <AppLogo color="black" />
                  <q-separator vertical class="q-ml-md" />
                  <h5 class="q-ml-md q-ma-none text-weight-medium text-black">
                    Avik
                  </h5>
                </div>
                <q-form ref="signInForm" @submit="onSignIn">
                  <div class="row">
                    <div class="col">
                      <q-input
                        v-model="model.email"
                        label="E-mail"
                        autocomplete="username"
                        :rules="[requiredRule]"
                        clearable
                      >
                        <template v-slot:prepend
                          ><q-icon name="mdi-account"
                        /></template>
                      </q-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <q-input
                        v-model="model.password"
                        autocomplete="current-password"
                        label="Lösenord"
                        :type="!showPassword ? 'password' : 'text'"
                        :rules="[requiredRule]"
                      >
                        <template v-slot:prepend
                          ><q-icon name="mdi-lock"
                        /></template>

                        <template v-slot:append>
                          <q-icon
                            :name="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            class="cursor-pointer"
                            @click="showPassword = !showPassword"
                          />
                        </template>
                      </q-input>
                    </div>
                  </div>
                  <q-slide-transition>
                    <div class="row" v-if="isSignInError">
                      <div class="col q-mb-sm">
                        <q-banner dense class="text-white bg-red" rounded>
                          <template v-slot:avatar>
                            <q-icon
                              size="xs"
                              name="mdi-alert-circle-outline"
                              color="white"
                            />
                          </template>
                          Det gick inte logga in!
                        </q-banner>
                      </div>
                    </div>
                  </q-slide-transition>
                  <div class="row">
                    <div class="col justify-end flex">
                      <q-btn
                        class="q-mr-sm"
                        icon="mdi-login"
                        no-caps
                        flat
                        type="button"
                        @click="() => signInSSO('saml.sj-avik')"
                        >SJ SSO</q-btn
                      >
                      <q-btn
                        color="primary"
                        label="Logga in"
                        type="submit"
                        :loading="signInPending"
                      />
                    </div>
                  </div>
                </q-form>
              </q-card-section>
            </q-card-section>
          </q-card>
          <SignInRegesSSO class="lt-sm" />
          <div class="full-width flex items-center justify-center q-pt-sm">
            <a class="text-white" @click="openResetPasswordModal"
              >Glömt lösenord?</a
            >
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script lang="ts">
import AppLogo from '@/components/AppLogo.vue'
import { QForm } from 'quasar'
import { defineComponent, ref, reactive, provide } from 'vue'
import { required as requiredRule } from '@/common/formValidationRules'
import { useProfile, signInSSO } from '@/composable/useProfile'
import { routerPush } from '@/router'
import SignInRegesSSO from '@/components/signIn/SignInRegesSSO.vue'
import { useModal } from '@/composable/useModal'
import AppResetPasswordModal from '@/components/AppResetPasswordModal.vue'

export default defineComponent({
  name: 'SignIn',

  components: {
    AppLogo,
    SignInRegesSSO,
    AppResetPasswordModal,
  },

  setup() {
    const resetPasswordModal = useModal()
    provide('reset-password-modal', resetPasswordModal)
    const signInForm = ref<QForm | null>(null)
    const isSignInError = ref(false)
    const model = reactive({
      email: '',
      password: '',
    })
    const showPassword = ref(false)
    const { signIn, signInPending } = useProfile()

    async function onSignIn() {
      isSignInError.value = false
      const isValid = await signInForm.value?.validate()
      if (!isValid) return
      try {
        await signIn(model)
        routerPush('projects')
      } catch (error) {
        isSignInError.value = true
      }
    }

    function openResetPasswordModal() {
      resetPasswordModal.openModal()
    }

    return {
      showPassword,
      signInForm,
      signInPending,
      requiredRule,
      model,
      onSignIn,
      isSignInError,
      signInSSO,
      openResetPasswordModal,
      resetPasswordModal,
    }
  },
})
</script>

<style lang="scss" module>
@import '@/styles/quasar.variables.scss';

.gradient {
  background: linear-gradient($primary, $accent);
}
</style>
