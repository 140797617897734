<template>
  <q-dialog :model-value="true" :persistent="true">
    <q-card style="min-width: 350px" square>
      <q-form
        ref="formRef"
        @submit.prevent="onResetPassword"
        autocomplete="off"
        greedy
      >
        <q-card-section class="flex items-center" v-if="!success">
          <q-icon size="sm" name="mdi-lock-reset" class="q-mr-md" />
          <div class="text-h6">Återställ lösenord</div>
        </q-card-section>

        <q-card-section class="flex items-center" v-else>
          <q-icon
            size="sm"
            class="q-mr-md"
            color="primary"
            name="mdi-check-circle-outline"
          />
        </q-card-section>

        <q-separator />
        <transition
          enter-active-class="animated animate__fadeIn"
          leave-active-class="animated animate__fadeOut"
          mode="out-in"
          :duration="140"
        >
          <q-card-section v-if="!success">
            <div class="row q-pb-md text-grey-7">
              Skriv in din e-post kopplat till din användare för att återställa
              lösenordet.
            </div>
            <div class="row">
              <div class="col-12">
                <q-input
                  autocomplete="off"
                  dense
                  v-model="model.email"
                  label="E-mail"
                  type="email"
                  :rules="[requiredRule]"
                />

                <q-slide-transition>
                  <div class="row" v-if="showError">
                    <div class="col q-mb-sm">
                      <q-banner dense class="text-white bg-red" rounded>
                        <template v-slot:avatar>
                          <q-icon
                            size="xs"
                            name="mdi-alert-circle-outline"
                            color="white"
                          />
                        </template>
                        Finns ingen användare med angiven e-mail!
                      </q-banner>
                    </div>
                  </div>
                </q-slide-transition>
              </div>
            </div>
          </q-card-section>

          <q-card-section v-else>
            Följ instruktionerna i emailet för att återställa ditt lösenord!
          </q-card-section>
        </transition>

        <q-card-actions align="right" v-if="!success">
          <q-btn
            flat
            type="button"
            label="Avbryt"
            @click="resetPasswordModal.closeModal()"
          />
          <q-btn
            flat
            label="Bekräfta"
            type="submit"
            color="primary"
            :loading="pendingResetPassword"
          />
        </q-card-actions>

        <q-card-actions align="right" v-else>
          <q-btn
            flat
            type="button"
            label="Ok"
            color="primary"
            @click="resetPasswordModal.closeModal()"
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { defineComponent, inject, ref } from 'vue'
import { required as requiredRule } from '@/common/formValidationRules'
import { QForm } from 'quasar'
import { useProfile } from '@/composable/useProfile'
import { UseModal } from '@/composable/useModal'

export default defineComponent({
  name: 'AppResetPasswordModal',

  setup() {
    const success = ref(false)
    const showError = ref(false)
    const { resetPassword, pendingResetPassword } = useProfile()
    const formRef = ref<QForm | null>(null)
    const resetPasswordModal = inject<UseModal<unknown>>('reset-password-modal')
    const model = ref({
      email: '',
    })

    async function onResetPassword() {
      showError.value = false
      const isValid = await formRef.value?.validate()
      if (!isValid) return
      const data = await resetPassword(model.value.email)

      if (data === 'auth/user-not-found') {
        showError.value = true
      } else if (data === 'ok') {
        success.value = true
      } else {
        resetPasswordModal?.closeModal()
      }
    }
    return {
      resetPasswordModal,
      model,
      requiredRule,
      onResetPassword,
      formRef,
      pendingResetPassword,
      showError,
      success,
    }
  },
})
</script>

<style lang="scss" module>
.docCode {
  border-radius: 4px;
  overflow: visible;
  padding: 0;
}

.docCodeInner {
  word-wrap: normal;
  background-color: #fdfdfd;
  color: #424242;
  font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
  font-size: 12px;
  -webkit-hyphens: none;
  hyphens: none;
  line-height: 1.5;
  margin: 0;
  position: relative;
  -moz-tab-size: 1;
  tab-size: 1;
  text-align: left;
  white-space: pre;
  word-break: normal;
  word-spacing: normal;
  padding: 4px;
  background-color: #f5f5f5;
  display: block;
  height: inherit;
  max-height: inherit;
  overflow: auto;
}
</style>
