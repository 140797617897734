
import { signInSSO } from '@/composable/useProfile'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SignInRegesSSO',

  setup() {
    return {
      signInSSO,
    }
  },
})
